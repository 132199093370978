import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import lutfor from '../../asset/image/lutfor.png';


class Analysis extends Component {

    constructor(){
        super();
        this.state={
            data:[
                {Technology:'HTML',Project:100},
                {Technology:'CSS',Project:95},
                {Technology:'JQUERY',Project:85},
                {Technology:'PHP',Project:90},
                {Technology:'LARAVEL',Project:100},
                {Technology:'Vue Js',Project:80},
                {Technology:'React Js',Project:70},
                {Technology:'SQL',Project:85},
               
            ]
        }
    }

    render() {
        var blue= "rgba(0,115,230,0.8)"
        return (
            <Fragment>
                <Container className="text-center">
                    <h1 className="serviceMainTitle">Technology Used</h1>
                    <Row>
                    <Col  lg={4} md={12} sm={12}>

                    <img src={lutfor} alt="Lutfor Rahman" className="img-fluid" style={{ width: '400px',height: '350px' }} />
                        {/* <p className="text-justify des">To build native android apps i use Java as well as kotline mainly. React JS is used for cross platform mobile application. I use MySQL database for relational database system. To build NoSQL application i use MongoDB. Firebase database system is used where it is necessary to provide realtime data flow facilities.
                            <br/>
                            <br/>
                            I always build dynamic application. Admin panel is the heart of such kinds of application. I always try to provide sufficient features in admin panel to dominate application. According to client demand I use PHP OOP, CodeIgniter and Laravel to build admin panel section.
                            <br/>
                            <br/>
                            Application security is a big deal for commercial application. I always ensure security portion of my application, moreover i build a security alert system, to notify admin when his system at risk.
                        </p> */}
                    </Col>
                        <Col style={{width:'800',height:'380px'}} lg={8} md={12} sm={12}>
                            <ResponsiveContainer>
                                <BarChart width={100} height={300} data={this.state.data}>
                                <XAxis dataKey="Technology"/>
                                    <Tooltip data="Project"/>
                                    <Bar dataKey="Project" fill={blue}>
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </Col>
                        
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default Analysis;