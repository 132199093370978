import React, { Component, Fragment, } from "react";
import { Container } from "react-bootstrap";
import Typical from "react-typical";
import portfolio from "../../asset/image/portfolio.jpg";

class TopBanner extends Component {
  render() {
    return (
      <Fragment>
        <Container fluid={true} className="topFixedBanner p-0">
          <section className="header-background pb-5">
            <div className="container pb-5">
              <div className="row">
                <div className="col-md-5 d-flex align-items-center">
                  <div className="mb-2">
                    <h4 className="text-light">Hi! I,m Mr. Lutfor Rahman</h4>
                    <h1 className="section-hadding text-primary">
                      I,m{" "}
                      <Typical
                        steps={[
                          "Programmer",
                          3000,
                          "Web Developer",
                          3000,
                          "Vue js Developer",
                          3000,
                          "React js Developer",
                          3000,
                          "Laravel Developer",
                          3000,
                        ]}
                        loop={Infinity}
                        wrapper="g"
                      />
                    </h1>
                    <p className="topSubTitle text-light  pt-3">
                      Web Developer with very interest in Vue
                      js, React js, JavaScript & Laravel. Experienced in Web Design
                      and Development. To achieve the objective fully prepared
                      to show my honesty, hard work, and sufficient confidence
                      along. Looking for opportunities.
                    </p>
                    <br />
                    <a
                      className="btn banner-button text-light"
                      href="https://docs.google.com/document/d/1TaskUZ3GitTvNm67LrF1bHghulXsOqQ4EiRyupGZpys"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download Resume
                    </a>
                    &nbsp;&nbsp;
                    <a
                      className="btn banner-button text-light"
                      href="https://www.fiverr.com/webatozexpert?up_rollout=true"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Hire Me
                    </a>
                  </div>
                </div>
                <div className="col-md-7 text-center">
                  <img
                    src={portfolio}
                    alt="Lutfor Rahman"
                    className="img-fluid"
                    style={{ width: "690px", height: "500px" }}
                  />
                </div>
              </div>
            </div>
          </section>
        </Container>
      </Fragment>
    );
  }
}
export default TopBanner;
