import { faFacebook, faLinkedin, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faHome, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

class Footer extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="text-center footerSection">
                    <Row>
                        <Col lg={3} md={6} sm={12} className="p-5 text-justify">
                            <h1 className="serviceName">Follow Me</h1>
                            <a className="socialLink" href="https://www.facebook.com/webatozexpert" target="_blank"><FontAwesomeIcon icon={faFacebook}/> Facebook</a><br/>
                            <a className="socialLink" href="https://www.youtube.com/channel/UCitLO_blW3s24b1qR9kzmAg" target="_blank"><FontAwesomeIcon icon={faYoutube}/> YouTube</a><br/>
                            <a className="socialLink" href="https://www.linkedin.com/in/md-lutfor-rahman-0541351a4/" target="_blank"><FontAwesomeIcon icon={faLinkedin}/> Linkedin</a><br/>
                        </Col>
                        <Col lg={3} md={6} sm={12} className="p-5 text-justify">
                            <h1 className="serviceName">Address</h1>
                            <p className="text-justify des"><FontAwesomeIcon icon={faHome}/>House-45,Road-07,Sector-04,Uttara,Dhaka-1230.Bangladesh</p>
                            <p className="text-justify des"><FontAwesomeIcon icon={faEnvelope}/> webatozexpert@gmail.com</p>
                            <p className="text-justify des"><FontAwesomeIcon icon={faPhone}/> +880 1913848150</p>
                        </Col>
                        <Col lg={3} md={6} sm={12} className="p-5 text-justify">
                            <h1 className="serviceName">Information</h1>
                            <Link className="footerLink" to="/about">About Me</Link><br/>
                            <a className="footerLink" href="https://docs.google.com/document/d/1TaskUZ3GitTvNm67LrF1bHghulXsOqQ4EiRyupGZpys" target="_blank" rel="noreferrer">Resume</a><br/>
                            <Link className="footerLink" to="/contact">Contact Me</Link>
                        </Col>
                        <Col lg={3} md={6} sm={12} className="p-5 text-justify">
                            <h1 className="serviceName">Legal</h1>
                            <Link className="footerLink" to="/Refund">Refund Policy</Link><br/>
                            <Link className="footerLink" to="/Terms">Terms And Condition</Link><br/>
                            <Link className="footerLink" to="/Privacy">Privacy Policy</Link>
                        </Col>
                    </Row>
                </Container>

                <Container fluid={true} className="text-center copyrightSection">
                <span>2018 &copy; All right reserved by <a href="https://averfresh.com.bd" rel="noreferrer" target='_blank' >Webatozexpert</a></span>
                </Container>


            </Fragment>
        );
    }
}

export default Footer;