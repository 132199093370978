import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BigPlayButton, Player } from "video-react";
import averfresh from "../../asset/image/averfresh .png";
import foodapps from "../../asset/image/foodapps.png";
import portfolio from "../../asset/image/portfolio.png";
import qail from "../../asset/image/qail.png";
import qal from "../../asset/image/qal.png";
import qfl from "../../asset/image/qfl.png";
import trpo from "../../asset/image/trpo.png";
class AllProjects extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
  }
  modalClose = () => this.setState({ show: false });
  modalOpen = () => this.setState({ show: true });
  render() {
    return (
      <Fragment>
        <Container className="text-center">
          <h1 className="serviceMainTitle">RECENT PROJECTS</h1>
          <Row>
            <Col lg={4} md={6} sm={12} className="p-2">
              <Card className="projectsCard">
                <a
                  href="https://portfolio.averfresh.com.bd"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Card.Img variant="top" src={portfolio} />
                </a>
                <Card.Body>
                  <Card.Title className="projectsCardTitle">
                    Portfolio Site
                  </Card.Title>
                  <Card.Text className="projectsCardDes">
                    Some quick example text to build on the card title and make.
                  </Card.Text>
                  <p>
                    <FontAwesomeIcon
                      onClick={this.modalOpen}
                      className="portplayBtn"
                      icon={faPlayCircle}
                    />
                  </p>
                  <Button variant="primary">
                    <Link className="link-style" to="/ProjectDetails">
                      Details
                    </Link>
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={6} sm={12} className="p-2">
              <Card className="projectsCard">
                <a
                  href="https://www.qfl.com.bd/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Card.Img variant="top" src={qfl} />
                </a>
                <Card.Body>
                  <Card.Title className="projectsCardTitle">
                    Company Website
                  </Card.Title>
                  <Card.Text className="projectsCardDes">
                    Some quick example text to build on the card title and make.
                  </Card.Text>
                  <p>
                    <FontAwesomeIcon
                      onClick={this.modalOpen}
                      className="portplayBtn"
                      icon={faPlayCircle}
                    />
                  </p>
                  <Button variant="primary">
                    <Link className="link-style" to="/ProjectDetails">
                      Details
                    </Link>
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={4} md={6} sm={12} className="p-2">
              <Card className="projectsCard">
                <a
                  href="https://qualityfoods.com.bd/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Card.Img variant="top" src={averfresh} />
                </a>
                <Card.Body>
                  <Card.Title className="projectsCardTitle">
                    Ecom & Shop Management
                  </Card.Title>
                  <Card.Text className="projectsCardDes">
                    Some quick example text to build on the card title and make.
                  </Card.Text>
                  <p>
                    <FontAwesomeIcon
                      onClick={this.modalOpen}
                      className="portplayBtn"
                      icon={faPlayCircle}
                    />
                  </p>
                  <Button variant="primary">
                    <Link className="link-style" to="/ProjectDetails">
                      Details
                    </Link>
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={4} md={6} sm={12} className="p-2">
              <Card className="projectsCard">
                <a
                  href="https://www.foodapps.com.bd/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Card.Img variant="top" src={foodapps} />
                </a>
                <Card.Body>
                  <Card.Title className="projectsCardTitle">
                    Ecom & Restaurant Management
                  </Card.Title>
                  <Card.Text className="projectsCardDes">
                    Some quick example text to build on the card title and make.
                  </Card.Text>
                  <p>
                    <FontAwesomeIcon
                      onClick={this.modalOpen}
                      className="portplayBtn"
                      icon={faPlayCircle}
                    />
                  </p>
                  <Button variant="primary">
                    <Link className="link-style" to="/ProjectDetails">
                      Details
                    </Link>
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={6} sm={12} className="p-2">
              <Card className="projectsCard">
                <a
                  href="http://123.200.6.82/qial/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Card.Img variant="top" src={qail} />
                </a>
                <Card.Body>
                  <Card.Title className="projectsCardTitle">
                    ERP & Factory Management
                  </Card.Title>
                  <Card.Text className="projectsCardDes">
                    Some quick example text to build on the card title and make.
                  </Card.Text>
                  <p>
                    <FontAwesomeIcon
                      onClick={this.modalOpen}
                      className="portplayBtn"
                      icon={faPlayCircle}
                    />
                  </p>
                  <Button variant="primary">
                    <Link className="link-style" to="/ProjectDetails">
                      Details
                    </Link>
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={6} sm={12} className="p-2">
              <Card className="projectsCard">
                <a
                  href="http://123.200.6.82/qial/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Card.Img variant="top" src={qal} />
                </a>
                <Card.Body>
                  <Card.Title className="projectsCardTitle">
                    ERP & Factory Management
                  </Card.Title>
                  <Card.Text className="projectsCardDes">
                    Some quick example text to build on the card title and make.
                  </Card.Text>
                  <p>
                    <FontAwesomeIcon
                      onClick={this.modalOpen}
                      className="portplayBtn"
                      icon={faPlayCircle}
                    />
                  </p>
                  <Button variant="primary">
                    <Link className="link-style" to="/ProjectDetails">
                      Details
                    </Link>
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={4} md={6} sm={12} className="p-2">
              <Card className="projectsCard">
                <a
                  href="https://www.qfl.com.bd/trpo/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Card.Img variant="top" src={trpo} />
                </a>
                <Card.Body>
                  <Card.Title className="projectsCardTitle">
                    ERP & Factory Management
                  </Card.Title>
                  <Card.Text className="projectsCardDes">
                    Some quick example text to build on the card title and make.
                  </Card.Text>
                  <p>
                    <FontAwesomeIcon
                      onClick={this.modalOpen}
                      className="portplayBtn"
                      icon={faPlayCircle}
                    />
                  </p>
                  <Button variant="primary">
                    <Link className="link-style" to="/ProjectDetails">
                      Details
                    </Link>
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            
          </Row>
        </Container>
        <Modal size="lg" show={this.state.show} onHide={this.modalClose}>
          <Modal.Body>
            <Player>
              <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
              <BigPlayButton position="center" />
            </Player>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.modalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default AllProjects;
