import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from 'react';
import { Button, Col, Container, Form, Row } from "react-bootstrap";

class ContactSection extends Component {
    render() {
        return (
            <Fragment>
                <Container className="mt-5">
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <h1 className="serviceName">Quick Connect</h1>
                            <Form>
                                <Form.Group>
                                    <Form.Label className="serviceDescription">Name</Form.Label>
                                    <Form.Control type="text"/>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label className="serviceDescription">Email Address</Form.Label>
                                    <Form.Control type="email"/>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label className="serviceDescription">Message</Form.Label>
                                    <Form.Control as="textarea" rows="3"/>
                                </Form.Group>

                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>

                        </Col>

                        <Col lg={6} md={6} sm={12}>
                            <h1 className="serviceName">Discuss Now</h1>
                            <p className="serviceDescription"> House-57, Road-07, Sector-04, Uttara, Dhaka-1230.Bangladesh</p>
                            <p className="serviceDescription"><FontAwesomeIcon icon={faEnvelope}/>  webatozexpert@gmail.com</p>
                            <p className="serviceDescription"><FontAwesomeIcon icon={faPhone}/> +880 1913848150</p> 
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3620.259271704932!2d89.44422201442035!3d24.854992951599186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fdabfa65fd3df5%3A0xd636559ea39360d5!2sAver-Fresh%20Limited!5e0!3m2!1sen!2sbd!4v1622213091134!5m2!1sen!2sbd" width="400" height="250"  allowfullscreen="" loading="lazy"></iframe>
                        </Col>
                    </Row>
                </Container>

            </Fragment>
        );
    }
}

export default ContactSection;